<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dense dark :style="stickyTop" extension-height="4">
			<v-toolbar-title>{{ $t("Klanten balans") }} </v-toolbar-title>
			<v-spacer />
			<span>Saldo: {{ $n(balanceTotal, "ccy") }}</span>
			<template #extension v-if="loading">
				<v-progress-linear bottom indeterminate color="white"></v-progress-linear>
			</template>
		</v-toolbar>
		<v-container>
			<v-sheet outlined>
				<v-data-table disable-pagination hide-default-footer :items="balanceSheet" :headers="headers"
					:height="sessionStore.contentHeight - 76 + 'px'" fixed-header dense>
					<template v-slot:[`item.debit`]="{ item }">
						<span v-if="item.debit">{{
							$n(item.debit, "ccy")
						}}</span>
					</template>
					<template v-slot:[`item.credit`]="{ item }">
						<span v-if="item.credit">{{
							$n(item.credit, "ccy")
						}}</span>
					</template>
					<template v-slot:[`item.balance`]="{ item }">
						<span>{{ $n(item.balance, "ccy") }}</span>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-btn icon @click.stop="openARLedger(item)">
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-sheet>
		</v-container>
	</v-container>
</template>
                
<script>
import caas from "@/services/caas";
import { useSessionStore } from "@/store/session";
export default {
	name: "ARBalanceSheet",
	computed: {
		balanceTotal() {
			let total = 0;
			this.balanceSheet.forEach((r) => {
				total += r.balance;
			});
			return total;
		},
		headers() {
			return [
				{ text: "Klantnr", value: "customerId", width: "60px" },
				{ text: "Naam", value: "customerName" },
				{ text: "Debet", value: "debit", align: "end" },
				{ text: "Credit", value: "credit", align: "end" },
				{ text: "Saldo", value: "balance", align: "end" },
				{ text: "", value: "actions" },
			];
		},
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 5,
			};
		},
	},
	data() {
		return {
			loading: false,
			balanceSheet: [],
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async getBalanceSheet() {
			this.loading = true;
			let params = {
				fiscalYear: Number(this.sessionStore.fiscalYear),
				fromPeriod: 0,
				toPeriod: 99,
			};
			const response = await caas.rpc("getARBalanceSheet", params);
			if (response.data) {
				this.balanceSheet = response.data.data.balance;
			} else if (response.data.error) {
				console.log(response.error);
			}
			this.loading = false;
		},
		refreshBalanceSheet() {
			this.balanceSheet = [];
			this.getBalanceSheet();
		},
		openARLedger(item) {
			this.$router.push({
				name: "ARLedger",
				params: {
					arLedger: item,
				},
			});
		},
	},
	mounted() {
		this.getBalanceSheet();
	},
};
</script>
